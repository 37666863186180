import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Home from './Home'
import Presale from './Presale'
import Vip from './VIP'

function App() {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/presale' element={<Presale />} />
      <Route path='/vip' element={<Vip />} />
    </Routes>
  )
}

export default App
