import React, { useEffect, useState } from 'react'
import '../../loader.js'
import Discord from './Discord'
const $ = window.$

function Header ({ endTime, isPublicSale }) {
  const [days, setDays] = useState('')
  const [hours, setHours] = useState('')
  const [minutes, setMinutes] = useState('')
  const [seconds, setSeconds] = useState('')
  useEffect(() => {
    const makeTimer = () => {
      let localEndTime = endTime
      localEndTime = (Date.parse(localEndTime) / 1000)
      let now = new Date()
      now = (Date.parse(now) / 1000)
      const timeLeft = localEndTime - now
      const days = Math.floor(timeLeft / 86400)
      if (timeLeft <= 0) return
      let hours = Math.floor((timeLeft - (days * 86400)) / 3600)
      let minutes = Math.floor((timeLeft - (days * 86400) - (hours * 3600)) / 60)
      let seconds = Math.floor((timeLeft - (days * 86400) - (hours * 3600) - (minutes * 60)))
      if (hours < '10') { hours = '0' + hours }
      if (minutes < '10') { minutes = '0' + minutes }
      if (seconds < '10') { seconds = '0' + seconds }
      setDays('<span class=day-time>' + days + '</span> <span class=day-txt>Days</span>')
      setHours('<span class=day-time>' + hours + '</span> <span class=day-txt>Hours</span>')
      setMinutes('<span class=day-time>' + minutes + '</span> <span class=day-txt>Minutes</span>')
      setSeconds('<span class=day-time>' + seconds + '</span> <span class=day-txt>Seconds</span>')
    }
    setInterval(makeTimer, 1000)
  }, [])

  return (
    <>
      <h1 className='fadeInUp'>{isPublicSale ? 'Mint in' : 'Presale in'}</h1>
      <div id='timer' className='fadeInUp'>
        <div id='days'>
          <div dangerouslySetInnerHTML={{ __html: days }} />
        </div>
        <div id='hours'>
          <div dangerouslySetInnerHTML={{ __html: hours }} />
        </div>
        <div id='minutes'>
          <div dangerouslySetInnerHTML={{ __html: minutes }} />
        </div>
        <div id='seconds'>
          <div dangerouslySetInnerHTML={{ __html: seconds }} />
        </div>
      </div>

      <p className='fadeInUp' style={{ fontWeight: 800 }}>
        {
          isPublicSale ? 'Public sale: December 02 at 9 PM EST' : 'Presale: December 01 at 9 PM EST'
        }
      </p>

      {
        isPublicSale && <Discord />
      }
    </>
  )
}

export default Header
