import React, { useState } from 'react'
import Scrollspy from 'react-scrollspy'
import Navbar from './components/Navbar'
import MintSection from './components/mint'
import Discover from './components/Discover'
import Feature from './components/Feature'
import Hero from './components/Hero'
import Footer from './components/Footer'
import Roadmap from './components/Roadmap'
import Faq from './components/FAQ'
import Team from './components/Team'

function Home () {
  const [currentSection, setCurrentSection] = useState('home')

  return (
    <main>
      <Scrollspy
        style={{ display: 'none' }}
        items={['home', 'our-bulls', 'roadmap', 'faq', 'team', 'footer']} onUpdate={(newSection) => {
          if (newSection && newSection.id) {
            setCurrentSection(newSection.id)
          } else {
            setCurrentSection('')
          }
        }}
        offset={-100}
      />
      <Navbar currentSection={currentSection} setCurrentSection={setCurrentSection} />
      <MintSection endTime={new Date('02 December 2021 21:00:00 GMT-05:00')} sale='PUBLIC' />
      <section id='our-bulls'>
        <Discover />
        <Feature />
        <Hero />
      </section>
      <Roadmap />
      <Faq />
      <Team />
      <Footer />
    </main>
  )
}

export default Home
