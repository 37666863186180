import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { connect } from '../../redux/blockchain/blockchainActions'
import { fetchData } from '../../redux/data/dataActions'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import '../../loader.js'
import Contract from 'web3-eth-contract'
import Header from './Header'
const $ = window.$

function MintSection ({ endTime, sale = 'PUBLIC' }) {
  const dispatch = useDispatch()
  const blockchain = useSelector((state) => state.blockchain)
  const data = useSelector((state) => state.data)
  const [claimingNft, setClaimingNft] = useState(false)
  const [feedback, setFeedback] = useState('')
  const [mintAmount, setMintAmount] = useState(1)
  const navigate = useNavigate()
  const [mintInfos, setMintInfos] = useState({
    open: false,
    supply: -1,
    price: 0,
    maxSupply: 0
  })

  const claimNFTs = async () => {
    const cost = mintInfos.price * 1e18
    setFeedback('')
    const gasLimit = process.env.REACT_APP_GAS_LIMIT
    const totalCostWei = String(cost * mintAmount)
    const totalGasLimit = String(gasLimit * mintAmount * 1.5)
    const txInfos = {
      gasLimit: String(totalGasLimit),
      to: process.env.REACT_APP_MAIN_CONTRACT_ADDRESS,
      from: blockchain.account,
      value: totalCostWei
    }
    const handleError = async () => {
      setClaimingNft(false)
      setFeedback('<div style="color: red">Error: An error occurred while minting your NFT. Please check the blockchain explorer for more details.</div>')
    }

    const confirm = () => {
      if (data.isWhitelisted && sale === 'WHITELIST') {
        setFeedback('<div style="color:#5cdb5c;"><strong>Pre-order successful</strong>: Your awesome NFT(s) will be automatically air-dropped to your wallet <strong>24h after public sale</strong>!</div>')
      } else {
        setFeedback(
          '<div style="color:#5cdb5c;"><strong>Mint successful!</strong> Go to <a style="color:white;" href="https://opensea.io/">OpenSea</a> to see your awesome NFT(s)!<br><strong>Remember</strong>: Putting it for sale under the minimum price of <strong>3 ETH</strong> will add a "<strong>BANNED</strong>" layer to your Rich Bull NFT.</div>'
        )
      }
      setClaimingNft(false)
      dispatch(fetchData(blockchain.account))
    }

    setClaimingNft(true)
    try {
      if (sale === 'PUBLIC') {
        await blockchain.smartContract.methods.mint(mintAmount).send(txInfos)
      } else {
        await blockchain.smartContract.methods.presaleMint(mintAmount).send(txInfos)
      }
      confirm()
    } catch (err) {
      handleError(err)
    }
  }

  const getBaseData = async () => {
    const abiResponse = await axios.get('/config/abi.json', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
    const abi = await abiResponse.data
    Contract.setProvider(process.env.REACT_APP_MAIN_CHAIN_RPC)
    const mainContract = new Contract(abi, process.env.REACT_APP_MAIN_CONTRACT_ADDRESS)
    const maxSupply = sale === 'PUBLIC' ? await mainContract.methods.maxSupply().call() : await mainContract.methods.maxPresaleSupply().call()
    const onlyWhitelisted = await mainContract.methods.whitelistMint().call()
    const onlyVip = await mainContract.methods.vipMint().call()
    console.log(onlyVip)
    const paused = await mainContract.methods.paused().call()
    if (!(onlyWhitelisted || onlyVip) && sale !== 'PUBLIC' && !paused) {
      return navigate('/')
    }
    const supply = sale === 'PUBLIC' ? await mainContract.methods.totalSupply().call() : await mainContract.methods.mintedPresaleSupply().call()
    const price = await (sale === 'PUBLIC' ? mainContract.methods.publicCost().call() : mainContract.methods.presaleCost().call())
    setMintInfos({
      price: parseInt(price) / 1e18,
      open: sale === 'PUBLIC' ? !paused && !onlyWhitelisted && !onlyVip : sale === 'WHITELIST' ? onlyWhitelisted : onlyVip,
      supply: parseInt(supply),
      maxSupply: parseInt(maxSupply)
    })
    setInterval(async () => {
      const onlyWhitelisted = await mainContract.methods.whitelistMint().call()
      const onlyVip = await mainContract.methods.vipMint().call()
      const supply = await mainContract.methods.totalSupply().call()
      const paused = await mainContract.methods.paused().call()
      if (!(onlyWhitelisted || onlyVip) && sale !== 'PUBLIC' && !paused) {
        return navigate('/')
      }
      setMintInfos({
        price: parseInt(price) / 1e18,
        maxSupply: parseInt(maxSupply),
        open: sale === 'PUBLIC' ? !paused && !onlyWhitelisted && !onlyVip : sale === 'WHITELIST' ? onlyWhitelisted : onlyVip,
        supply: parseInt(supply)
      })
    }, 2000)
  }

  useEffect(() => {
    getBaseData()
  }, [])

  const getData = () => {
    if (blockchain.account !== '' && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account))
    }
  }

  useEffect(() => {
    getData()
  }, [blockchain.account])
  useEffect(() => {
    const makeTimer = () => {
      let localEndTime = endTime
      localEndTime = (Date.parse(localEndTime) / 1000)
      let now = new Date()
      now = (Date.parse(now) / 1000)
      const timeLeft = localEndTime - now
      if (timeLeft <= 0) return
      const days = Math.floor(timeLeft / 86400)
      let hours = Math.floor((timeLeft - (days * 86400)) / 3600)
      let minutes = Math.floor((timeLeft - (days * 86400) - (hours * 3600)) / 60)
      let seconds = Math.floor((timeLeft - (days * 86400) - (hours * 3600) - (minutes * 60)))
      if (hours < '10') { hours = '0' + hours }
      if (minutes < '10') { minutes = '0' + minutes }
      if (seconds < '10') { seconds = '0' + seconds }
      $('#days').html('<span class=day-time>' + days + '</span> <span class=day-txt>Days</span>')
      $('#hours').html('<span class=day-time>' + hours + '</span> <span class=day-txt>Hours</span>')
      $('#minutes').html('<span class=day-time>' + minutes + '</span> <span class=day-txt>Minutes</span>')
      $('#seconds').html('<span class=day-time>' + seconds + '</span> <span class=day-txt>Seconds</span>')
    }
    setInterval(makeTimer, 1000)
  }, [])

  const soldOut = mintInfos.supply !== -1 && mintInfos.supply >= mintInfos.maxSupply
  const isInPresale = (data.isWhitelisted && sale === 'WHITELIST') || (data.isVip && sale === 'VIP')
  const canMint = mintInfos.open || (endTime < new Date())
  const isEligible = isInPresale || canMint || !blockchain.account

  return (
    <section className='header' id='home'>
      <img className='bull-image' src='./img/header-bg.png' alt='Bull Image' />
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className='header-cont'>
              {
                !canMint && (
                  <Header endTime={endTime} isPublicSale={sale === 'PUBLIC'} />
                )
              }
              {
                canMint && mintInfos.supply !== -1 && (
                  <>
                    {
                      soldOut
                        ? (
                          <h1>Sold out</h1>
                          )
                        : (
                          <h1>{sale === 'PUBLIC' ? 'Mint' : 'Presale'} is live!</h1>
                          )
                    }
                    {
                      mintInfos.supply !== -1 && (
                        <h3 style={{ marginTop: '-1%' }}>Already <u>{mintInfos.supply}</u> Rich Bulls minted.</h3>
                      )
                    }
                    <br />
                    <div id='timer' className='fadeInUp'>
                      <div id='dayss'>
                        <span className='day-time black-bg'><img src='https://i.imgur.com/k2gSHTC.jpeg' /></span>
                      </div>
                      <div id='hourss'>
                        <span className='day-time black-bg'><img src='https://i.imgur.com/AVu8vgW.jpeg' /></span>
                      </div>
                      <div id='minutess'>
                        <span className='day-time black-bg'><img src='https://i.imgur.com/mBTcfoL.png' /></span>
                      </div>
                      <div id='secondss'>
                        <span className='day-time black-bg'><img src='https://i.imgur.com/JBoj71B.gif' /></span>
                      </div>
                    </div>
                    {
                      !soldOut && isEligible && (
                        <>
                          <p className='fadeInUp' style={{ fontWeight: 800 }}>
                            PRICE:
                            <span id='total' style={{ color: '#5cdb5c', marginLeft: '3px', marginRight: '3px' }}>
                              {(mintInfos.price * mintAmount).toFixed(2)}
                            </span>
                            {process.env.REACT_APP_MAIN_CHAIN_CURRENCY_NAME} + Gas
                          </p>
                          {
                            blockchain.account === '' ||
                              blockchain.smartContract === null
                              ? (
                                <>
                                  {blockchain.errorMsg !== ''
                                    ? (
                                      <>
                                        <p>
                                          {blockchain.errorMsg}
                                        </p>
                                      </>
                                      )
                                    : null}
                                  <button
                                    className='btn2 hover'
                                    style={{ border: 'none', marginTop: '0' }}
                                    onClick={
                                      (e) => {
                                        e.preventDefault()
                                        dispatch(connect())
                                        getData()
                                      }
                                    }
                                  >
                                    Connect Wallet
                                  </button>
                                </>
                                )
                              : (
                                <>
                                  <div className='frm'>
                                    <div className='incr'>
                                      <div
                                        id='minus'
                                        className={sale !== 'PUBLIC' || mintAmount - 1 <= 0 ? 'mint-change-disabled' : 'mint-change'}
                                        onClick={() => sale !== 'PUBLIC' || mintAmount - 1 <= 0 ? false : setMintAmount(mintAmount - 1)}
                                      >-
                                      </div>
                                      <span id='quantity'>{mintAmount}</span>
                                      <div
                                        id='plus'
                                        className={sale !== 'PUBLIC' || (mintInfos.price * (1 + mintAmount)).toFixed(1) >= data.balance ? 'mint-change-disabled' : 'mint-change'}
                                        onClick={() => sale !== 'PUBLIC' || mintInfos.price * (1 + mintAmount).toFixed(1) >= data.balance ? false : setMintAmount(mintAmount + 1)}
                                      >+
                                      </div>
                                    </div>
                                    <div
                                      id='maxBtn' className='max-button inter button-4 w-button'
                                    ><span id='max' style={{ marginRight: '5px' }}>You have:<br /><span style={{ color: '#5cdb5c' }}>{data.balance} {process.env.REACT_APP_MAIN_CHAIN_CURRENCY_NAME}</span></span>
                                    </div>
                                  </div>
                                  <button
                                    className='btn2 hover'
                                    disabled={claimingNft || !mintInfos.open}
                                    onClick={
                                      (e) => {
                                        e.preventDefault()
                                        claimNFTs()
                                        getData()
                                      }
                                    }
                                    style={{ backgroundColor: '#5cdb5c', border: 'none' }}
                                  >
                                    {!mintInfos.open ? 'Minting is not available yet!' : claimingNft ? 'Minting...' : 'Mint'}
                                  </button>
                                </>
                                )
                          }
                        </>
                      )
                    }

                    <br />
                    {/* NOT ENOUGH FUNDS ERROR (or other errors): */}
                    {
                      feedback && feedback.length > 0 && (
                        <div dangerouslySetInnerHTML={{ __html: feedback }} />
                      )
                    }
                  </>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MintSection
