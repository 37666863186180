import React, { useEffect } from 'react'
import { Navbar, Container, Nav } from 'react-bootstrap'

import '../loader.js'
const $ = window.$

function PageNavbar({ currentSection, setCurrentSection }) {
  useEffect(() => {
    $(window).scroll(function () {
      $('nav').toggleClass('scrolled', $(this).scrollTop() > 50)
    })
  })
  return (
    <Navbar collapseOnSelect expand='md' fixed='top'>
      <Container>
        <Navbar.Brand href='#home'>RICH BULLS</Navbar.Brand>
        <Navbar.Toggle aria-controls='navbarText' />
        <Navbar.Collapse id='navbarText'>
          <Nav
            className='me-auto my-2 my-lg-0'
            navbarScroll={false}
          >
            <Nav.Link href='#home' active={currentSection === 'home'}>Mint</Nav.Link>
            <Nav.Link href='#our-bulls' active={currentSection === 'our-bulls'}>Rich Bulls</Nav.Link>
            <Nav.Link href='#roadmap' active={currentSection === 'roadmap'}>Roadmap</Nav.Link>
            <Nav.Link href='#faq' active={currentSection === 'faq'}>Faq</Nav.Link>
            <Nav.Link href='#team' active={currentSection === 'team'}>Team</Nav.Link>
          </Nav>
          <span className='navbar-text'>
            <ul className='list-unstyled nab-link'>
              <li>
                <a
                  href='https://opensea.io/collection/richbullsclub'
                  target='_blank'
                  rel='noreferrer'
                >
                  <i className='bi bi-tsunami' />
                </a>
              </li>
              <li>
                <a
                  href='https://instagram.com/richbullsclubnft'
                  target='_blank'
                  rel='noreferrer'
                >
                  <i className='bi bi-instagram' />
                </a>
              </li>
              <li>
                <a
                  href='https://twitter.com/Kiranyx'
                  target='_blank'
                  rel='noreferrer'
                >
                  <i className='bi bi-twitter' />
                </a>
              </li>
            </ul>
            <a
              href='/presale'
              target='_blank'
              rel='noreferrer'
            >
              <button style={{ color: 'black' }}>
                Presale
              </button>
            </a>
          </span>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default PageNavbar
