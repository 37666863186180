import React from 'react'

function Feature () {
  return (
    <section className='feature' id='feature'>
      <div className='container'>
        <div className='row align-items-center justify-content-between my-row'>
          <div className='col-12 col-md-6 col-xl-6 fadeIn'>
            <img src='img/mysticbull.gif' alt='Bull images' />
          </div>
          <div className='col-12 col-md-6 col-xl-5'>
            <span>MYTHIC</span>
            <h2>BULLS</h2>
            <p>Once <strong>over 85%</strong> of the Rich Bulls are sold, the lucky owners of the Lambo Mythic Bull, the Laren Mythic Bull, and the Rary Mythic Bull will respectively win a brand new <strong>2020 Lamborghini Huracan</strong>, a <strong>2020 McLaren 600LT</strong>, a <strong>2019 Ferrari 488 GTB</strong> or <strong>240 000$</strong> in ETH!</p>
            <a href='https://discord.gg/u6zR56asR7' target='_blank' rel='noreferrer'><button style={{ backgroundColor: 'rgb(255, 0, 8)', borderRadius: '50px', fontWeight: 500, fontSize: '14px', letterSpacing: '0.5px', padding: '10px 5px 10px 18px', marginLeft: '5px', transition: 'all 0.3s ease-in-out 0s', border: '1px solid rgb(255, 0, 8)', color: 'black!important' }}>Join Our Discord  <i className='bi bi-discord' style={{ backgroundColor: '#000', color: '#ff0008', borderRadius: '50%', padding: '7px 10px', fontSize: '16px', margin: '0 0 0 7px', transition: '0.3s ease-in-out' }} /></button></a><br /><br /><br />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Feature
