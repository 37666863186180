import React, { useEffect, useState } from 'react'
import axios from 'axios'
import '../../loader.js'

function Discord () {
  const [discordData, setDiscordData] = useState({ members: 0, online: 0 })

  useEffect(() => {
    const fetchData = async () => {
      const dataReq = await axios.get('https://discordapp.com/api/v6/invite/u6zR56asR7?with_counts=true')
      const data = dataReq.data
      setDiscordData({
        members: data.approximate_member_count,
        online: data.approximate_presence_count
      })
    }
    fetchData()
    setInterval(function () { fetchData() }, 10000)
  }, [])

  return (
    <div id='discordInviteBox' version={1.0}>
      <div id='discordInvite' style={{ width: '385px' }}>
        <h5 id='introText' className='noselect loadHidden' style={{ display: 'block' }}>
          Join our Discord to secure a presale slot!
        </h5>
        <div id='discordData'>
          <div
            id='serverImg'
            className='discordLink loadHidden'
            style={{ background: 'url("https://cdn.discordapp.com/icons/907320417008631818/a_ae8e872d97a6369a9fc58222986c9b5a.jpg") 50% 50% / 100% 100% repeat scroll padding-box padding-box rgb(54, 57, 63)', display: 'block' }}
          />
          <div id='discordInfo'>
            <div id='serverNameBox' className='discordLink'>
              <span className='noselect' id='serverName'>
                Rich Bulls Club
              </span>
            </div>
            <div id='status' className='loadHidden' style={{ display: 'block' }}>
              <div id='statusIndicators' className='noselect'>
                <i id='onlineInd' />
                <span id='numOnline'>
                  {discordData.online} Online
                </span>
                <i id='offlineInd' />
                <span id='numTotal'>
                  {discordData.members} Member
                </span>
              </div>
            </div>
          </div>
          <button type='button' className='discordLink' id='callToAction'>
            <div id='buttonText' className='noselect'>
              <a href='https://discord.com/invite/u6zR56asR7' target='_blank' rel='noreferrer' style={{ color: 'white', textDecoration: 'none' }}>
                Join
              </a>
            </div>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Discord
