import React from 'react'

function Team () {
  return (
    <section className='our-team' id='team'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 text-center fadeInUp'>
            <span>THE</span>
            <h2>TEAM</h2>
          </div>
          <div className='col-6 col-sm-6 col-md-3 fadeInUp'>
            <div className='img-hov-effect'>
              <div className='content'>
                <div className='content-overlay' />
                <img
                  className='content-image'
                  src='img/Kiranyx.png'
                  alt='Image'
                />
                <div className='content-details fadeIn-bottom'>
                  <a href='https://twitter.com/Kiranyx' target='_blank'>
                    <i className='bi bi-twitter' />
                  </a>
                </div>
              </div>
              <p>@Bull Kiranyx - Mastermind</p>
              <span style={{ color: 'rgba(255,255,255,.8)!important' }}>
                Business strategy expert with eight years of experience in the
                gaming industry, e-commerce, and finance.
              </span>
            </div>
          </div>
          <div className='col-6 col-sm-6 col-md-3 fadeInUp'>
            <div className='img-hov-effect'>
              <div className='content'>
                <div className='content-overlay' />
                <img className='content-image' src='img/Tony.png' alt='Image' />
                <div className='content-details fadeIn-bottom'>
                  <a href='https://instagram.com/anthonydxv' target='_blank'>
                    <i className='bi bi-instagram' />
                  </a>
                </div>
              </div>
              <p>@Bull Tony - Marketing Guru</p>
              <span style={{ color: 'rgba(255,255,255,.8)!important' }}>
                Talented and self-made, Tony has access to an extensive network of
                celebrities and influencers.
              </span>
            </div>
          </div>
          <div className='col-6 col-sm-6 col-md-3 fadeInUp'>
            <div className='img-hov-effect'>
              <div className='content'>
                <div className='content-overlay' />
                <img
                  className='content-image'
                  src='img/Corentin.png'
                  alt='Image'
                />
                <div className='content-details fadeIn-bottom'>
                  <a href='https://twitter.com/cryptorentin' target='_blank'>
                    <i className='bi bi-twitter' />
                  </a>
                </div>
              </div>
              <p>@Bull Cory - Programmer</p>
              <span style={{ color: 'rgba(255,255,255,.8)!important' }}>
                Blockchain genius with years of experience in the crypto, yield farming and NFT fields.
              </span>
            </div>
          </div>
          <div className='col-6 col-sm-6 col-md-3 fadeInUp'>
            <div className='img-hov-effect'>
              <div className='content'>
                <div className='content-overlay' />
                <img className='content-image' src='img/Luisa.png' alt='Image' />
                <div className='content-details fadeIn-bottom'>
                  <a href='https://twitter.com/LuisaDraws' target='_blank'>
                    <i className='bi bi-twitter' />
                  </a>
                </div>
              </div>
              <p>@Bull Luisa - Art Director</p>
              <span style={{ color: 'rgba(255,255,255,.8)!important' }}>
                Talented, kind and passionate, Luisa put all her heart into Rich
                Bulls art for an outstanding result.
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Team
