import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'

function Footer () {
  const [showTos, toggleTos] = useState(false)
  return (
    <footer className='footer'>
      <div className='footer-bg'>
        <div className='container'>
          <div className='row fadeInUp'>
            <div className='col-12 col-md-3 col-lg-3 col-xl-2 text-center text-md-start pb-5 pb-md-0'>
              <h2>RICH BULLS</h2>
            </div>
            <div className='col-12 col-md-6 col-lg-6 col-xl-8 text-center pb-5 pb-md-0'>
              <ul className='list-unstyled footer-links'>
                <li><a href='https://opensea.io/collection/richbullsclub' rel='noreferrer' target='_blank'><i className='bi bi-tsunami' /></a></li>
                <li><a href='https://instagram.com/richbullsclubnft' rel='noreferrer' target='_blank'><i className='bi bi-instagram' /></a></li>
                <li><a href='https://twitter.com/Kiranyx' rel='noreferrer' target='_blank'><i className='bi bi-twitter' /></a></li>
                <li><a href='https://discord.gg/u6zR56asR7' rel='noreferrer' target='_blank'><i className='bi bi-discord' /></a></li>
              </ul>
              <span>
                Open-source smart contract:
                <a href={`https://etherscan.io/address/${process.env.REACT_APP_MAIN_CONTRACT_ADDRESS}`} target='_blank' rel='noreferrer'>
                  {' '}{process.env.REACT_APP_MAIN_CONTRACT_ADDRESS}
                </a>
              </span>
            </div>
            <div className='col-12 col-md-3 col-lg-3 col-xl-2 text-center text-md-start'>
              <h6>KodakMoney LLC</h6>
              <a href='https://opencorporates.com/companies/us_nm/6272509' rel='noreferrer' target='_blank'><strong>Company number</strong>: 6272509</a>
              <p>150 Central Park Square, Suite #2, Los Alamos, NM, 87544, USA</p>
            </div>
            <div className='col-12'>
              <div className='copy'>
                <a data-bs-toggle='modal' data-bs-target='#exampleModal' style={{ cursor: 'pointer' }} onClick={() => toggleTos(true)}>Terms of Service</a>
                <p>© 2021 Rich Bulls Club. All Rights Reserved</p>
                <a className='copyrighted-badge' title='Copyrighted.com Registered &amp; Protected' rel='noreferrer' target='_blank' href='https://www.copyrighted.com/website/xV6T3IjJFtKL7YKM'>
                  <img alt='Copyrighted.com Registered &amp; Protected' border='0' width='125' height='25' srcSet='https://static.copyrighted.com/badges/125x25/01_1_2x.png 2x' src='https://static.copyrighted.com/badges/125x25/01_1.png' />
                </a>
                <script src='https://static.copyrighted.com/badges/helper.js' />
                {/* Modal */}
                <Modal show={showTos} size='lg' onHide={() => toggleTos(false)}>
                  <div className='modal-dialog modal-dialog-centered modal-lg my-0'>
                    <div className='modal-content'>
                      <div className='modal-body'>
                        <div className='modl-cont' style={{ fontWeight: 400 }}>
                          <h2>TERMS OF SERVICE</h2>
                          <br /><br />
                          The Rich Bulls Club website allows you to obtain unique tokens (“NFTs”) by minting on the ETHEREUM blockchain and is made available to you by KodakMoney LLC (“KodakMoney LLC”).
                          <br /><br />
                          <strong>Acceptance</strong>
                          <br /><br />
                          By participating in the pre-sale or public sale, completing the minting of NFTs and using the Rich Bulls Club website, you confirm that you understand and agree to these terms and conditions, together with any documents that may be expressly referred to and are incorporated by reference (“Terms”).
                          <br /><br />
                          These Terms constitute a legal agreement between you and KodakMoney LLC and govern your access to and use of the Rich Bulls Club website, including any content, functionality, and services offered on or through richbullsclub.io (the “Site”).
                          <br /><br />
                          KodakMoney LLC reserves the right to change or modify these terms at any time and at our sole discretion. You agree and understand that by accessing or using the Rich Bulls Club website and the Site following any change to these Terms, you are regarded as having agreed to the revised Terms.
                          <br /><br />
                          <strong> Definitions</strong>
                          <br /><br />
                          In these Terms:
                          <br /><br />
                          <strong>“Non-fungible token” “NFT”</strong>: Non-fungible token is a commercial term to describe items like your furniture, a picture image file, or your laptop. These things are not interchangeable for other items because they have unique properties. NFTs are tokens that we can use to represent ownership of unique items. They let us tokenize things like art, collectibles, even real estate. They can only have one official owner at a time and they're secured by the Ethereum blockchain – no one can modify the record of ownership or copy/paste a new NFT into existence.
                          <br /><br />
                          <strong>“Fungible items”</strong>: on the other hand, can be exchanged because their value defines them rather than their unique properties. For example, ETH or dollars are fungible because 0.1 ETH / $1 USD is exchangeable for another 0.1 ETH / $1 USD.
                          <br /><br />
                          <strong>“Applicable Law”</strong>: any law, rule, statute, subordinate legislation, regulation, by-law order, ordinance, protocol, code, guideline, treaty, policy, notice, direction or judicial, arbitral, administrative, ministerial or departmental judgment, award, decree, treaty, directive, or other requirement or guideline published or in force at any time which applies to or is otherwise intended to govern or regulate any person (including all parties to this Terms), property, transaction, activity, event or other matter, including any rule, order, judgment, directive or other requirement or guideline issued by any governmental or regulatory authority;
                          <br /><br />
                          "we/us/our" means KodakMoney LLC;
                          <br /><br />
                          "you/your" means the user of the Rich Bulls Club website;
                          <br /><br />
                          <strong> Changes to the Rich Bulls Club website</strong>
                          <br /><br />
                          We may in our absolute and sole discretion change, update, amend, remove, or discontinue any part of the Site, the services and the Content at any time without prior notice to you.
                          <br /><br />
                          <strong> Your Ownership of the NFT</strong>
                          <br /><br />
                          When you buy an NFT on the Rich Bulls Club website, you own the NFT and have the right to sell or give away the NFT. If the NFT is associated with a perk as specified in the roadmap or on any part of the Site, you will have a worldwide, perpetual, exclusive, transferable, right to benefit of the said perk, for so long as you own the NFT.
                          <br /><br />
                          The user that is buying and minting our Rich Bulls Club artwork is the owner of the Non Fungible Token (NFT) crated by validating the transaction. Each Rich Bull is an NFT on the Ethereum blockchain. When you purchase an NFT, you own all the rights to the underlying Rich Bull. Ownership of the NFT is mediated entirely and only by the Smart Contract and the Ethereum Network which means that, at no point, may we seize, freeze, or otherwise modify the ownership of any Rich Bull.
                          <br /><br />
                          Subject to your continued compliance with these Terms, Kodak Money LLC grants you a worldwide, royalty-free license to use, copy, and display the purchased Rich Bull(s), along with any extensions that you choose to create or use, solely for the following purposes: (i) for your own personal, non-commercial use; (ii) as part of a marketplace that permits the purchase and sale of your Rich Bull/NFT, provided that the marketplace cryptographically verifies each Rich Bull owner’s rights to display the Rich Bull and ensures that only the actual owner can display the Rich Bull; or (iii) as part of a third party website or application that permits the inclusion, involvement, or participation of your Rich Bull, provided that the website/application cryptographically verifies each Rich Bull owner’s rights to display the Rich Bull and ensures that only the actual owner can display the Rich Bull, and provided that the Rich Bull is no longer visible once the owner of the Rich Bull leaves the website/application.
                          <br /><br />
                          Subject to your continued compliance with these Terms, Kodak Money LLC grants you an unlimited, worldwide license to use, copy, and display the purchased Rich Bull for the purpose of creating derivative works based upon the Rich Bull (“Commercial Use”). Examples of such Commercial Use would be the use of the Rich Bull to produce and sell merchandise products (T-Shirts etc.) displaying copies of the Rich Bull. For the sake of clarity, nothing in this section will be deemed to restrict you from (i) owning or operating a marketplace that permits the use and sale of Rich Bull generally, provided that the marketplace cryptographically verifies each Rich Bull owner’s rights to display the Rich Bull and ensures that only the actual owner can display the Rich Bull; (ii) owning or operating a third party website or application that permits the inclusion, involvement, or participation of Rich Bull generally, provided that the third party website or application cryptographically verifies each Rich Bull owner’s rights to display the Rich Bull and ensures that only the actual owner can display the Rich Bull, and provided that the Rich Bull is no longer visible once the owner of the Purchased Rich Bull leaves the website/application; or (iii) earning revenue from any of the foregoing.
                          <br /><br />
                          <strong> Data Protection / Privacy Notice</strong>
                          <br /><br />
                          By using the Rich Bulls Club website, you confirm that you have read and understood our Privacy Notice and understand how we collect, use, disclose and share amongst ourselves your Personal Data and disclose such Personal Data to our authorised service providers and relevant third parties. We will only share your Personal Data in order to facilitate and administer your use of the Rich Bulls Club website or otherwise if required by law. Such data controller will manage and protect your personal data in accordance with all applicable data protection laws.
                          <br /><br />
                          We reserve the right at any time to satisfy our internal requirement as to your Personal Data (for example, by requesting relevant original documents) including for the purposes of preventing fraud and/or anti-money laundering and counter-terrorist financing purposes.
                          <br /><br />
                          <strong> Risks</strong>
                          <br /><br />
                          You understand and agree that your access and use of the Rich Bulls Club website is subject to certain risks including without limitation:
                          <br /><br />
                          i. Price and liquidity of blockchain assets, including the NFTs, are extremely volatile and may be subject to fluctuations.
                          <br /><br />
                          ii. Fluctuations in the price of other digital assets could materially and adversely affect the NFTs;
                          <br /><br />
                          iii. Legislative and regulatory changes or actions may adversely affect the use, transfer, and value of the NFTs;
                          <br /><br />
                          iv. NFTs are not legal tender and are not back by any government;
                          <br /><br />
                          v. Transactions involving NFTs may be irreversible, and losses due to fraudulent or accidental transactions may not be recoverable.
                          <br /><br />
                          vi. The value of NFTs may be derived from the continued willingness of market participants to exchange fiat currency or digital assets for NFTs, and therefore the value of NFTs is subject to the potential for permanent or total loss of value should the market for NFTs disappear.
                          <br /><br />
                          You understand and agree that you are solely responsible for determining the nature, potential value, suitability, and appropriateness of these risks for yourself. KodakMoney LLC does not give any advice or recommendations regarding the NFTs. You understand and agree that you access and use the Rich Bulls Club websiten at your own risk. You understand and agree that KodakMoney LLC will not be responsible for any communication failures, disruptions, errors, or distortions you may experience when using the NFTs or the Rich Bulls Club website.
                          <br /><br />
                          <strong> Taxes</strong>
                          <br /><br />
                          You agree that you are solely responsible for determining what, if any, taxes apply to your NFT transactions on the Rich Bulls Club Platform. Neither KodakMoney LLC nor any other KodakMoney LLC Party is responsible for determining the taxes that may apply to your NFT transactions.
                          <br /><br />
                          <strong> Giveaways, Roadmap and Squid Game</strong>
                          <br /><br />
                          Kodak Money LLC commits to:
                          <br /><br />
                          i. Send $10 000 US DOLLARS in Ethereum to every person who mints a "Primal" Rich Bull.
                          <br /><br />
                          ii. Buy a 2020 Lamborghini Huracan at a maximum listed price of $240 000 US dollars or send $240 000 US dollars worth of ETH to the person who mints the Mythic "Lambo" Rich Bull when at least 85% of the Rich Bulls Club collection is sold.
                          <br /><br />
                          iii. Buy a 2020 McLaren 600LT at a maximum listed price of $240 000 US dollars or send $240 000 US dollars worth of ETH to the person who mints the Mythic "Laren" Rich Bull when at least 85% of the Rich Bulls Club collection is sold.
                          <br /><br />
                          iv. Buy a 2019 Ferrari 488 GTB at a maximum listed price of $240 000 US dollars or send $240 000 US dollars worth of ETH to the person who mints the Mythic "Rary" Rich Bull when at least 85% of the Rich Bulls Club collection is sold.
                          <br /><br />
                          v. Randomly select 100 holders (including the 10 owners of Primal and Mythic Bulls) to participate in a massive, real-life, and all-inclusive (paid flight, food, and hotel) 4-days Squid Game event in which the winner will be given $1 MILLION US dollars.
                          <br /><br />
                          <strong> Limitation of Liability</strong>
                          <br /><br />
                          TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL KodakMoney LLC BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFIT OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING FROM THESE TERMS, THE SITE, PRODUCTS OR THIRD PARTY SITES AND PRODUCTS, OR FOR ANY DAMAGES RELATED TO LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, OR LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE AND EVEN IF KodakMoney LLC HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING LIMITATIONS OF LIABILITY SHALL NOT APPLY TO LIABILITY OF KodakMoney LLC FOR PERSONAL INJURY CAUSED BY KodakMoney LLC’S NEGLIGENCE OR ANY INJURY CAUSED BY KodakMoney LLC’S FRAUD OR FRAUDULENT MISREPRESENTATION.
                          <br /><br />
                          <strong> Indemnification</strong>
                          <br /><br />
                          To the fullest extent permitted by Applicable Law, you agree to indemnify, defend and hold harmless KodakMoney LLC and our past, present and future employees, officers, directors, contractors, consultants, equity holders, suppliers, vendors, service providers, parent companies, subsidiaries, affiliates, agents, representatives, predecessors, successors and assigns (individually and collectively the “KodakMoney LLC Parties”), from and against all actual or alleged third party claims, damages, awards, judgments, losses, liabilities, obligations, penalties, interest, fees, expenses (including, without limitation, court costs, costs of settlement and costs of pursuing indemnification and insurance), of every kind and nature whatsoever, whether known or unknown, foreseen or unforeseen, matured or unmatured, or suspected or unsuspected, in law or equity, whether in tort, contract or otherwise (collectively, “Claims”), including, but not limited to, damages to property or personal injury, that are caused by, arise out of or are related to (a) your use or misuse of the Site, Content or NFTs, (b) your breach of these Terms, and (c) your breach or violation of the rights of a third party, including another user or third party service provider. You agree to promptly notify KodakMoney LLC of any third party Claims and cooperate with the KodakMoney LLC Parties in defending such Claims. You further agree that the KodakMoney LLC Parties shall have control of the defense or settlement of any third party Claims.
                          <br /><br />
                          THIS INDEMNITY IS IN ADDITION TO, AND NOT IN LIEU OF, ANY OTHER INDEMNITIES THAT MAY BE SET FORTH IN A WRITTEN AGREEMENT BETWEEN YOU AND KodakMoney LLC.
                          <br /><br />
                          <strong> Severability</strong>
                          <br /><br />
                          If any provision of these Terms shall be found by any court or administrative body of competent jurisdiction to be invalid or unenforceable, the invalidity or unenforceability of such provision shall not affect the other provisions of these Terms and all provisions not affected by such invalidity or unenforceability shall remain in full force and effect. Such provision will be changed and interpreted to accomplish the objectives of the provision to the greatest extent possible under any Applicable Laws.
                          <br /><br />
                          <strong> Waiver</strong>
                          <br /><br />
                          These Terms shall not be waived in whole or in part except where agreed by the parties in writing.
                          <br /><br />
                          The delay of enforcement or the non-enforcement of any of the terms of these Terms by any party shall not be construed as a waiver of any of the other rights of that party arising out of the breach or any subsequent breach of any of these Terms and no right, power or remedy conferred upon or reserved for any party in these Terms is exclusive of any other right, power or remedy available to that party and each such right, power or remedy shall be cumulative.
                          <br /><br />
                          <strong> Third Party Rights</strong>
                          <br /><br />
                          Other than the entities within the KodakMoney LLC group, a person who is not a party to these Terms has no right to enforce any of these Terms.
                          <br /><br />
                          <strong> Governing Law and Jurisdiction</strong>
                          <br /><br />
                          These Terms are governed by and shall be construed in accordance with the laws of the United States of America without regard to any choice or conflict of laws rules. Any dispute, controversy, or claim, whether contractual or non-contractual, arising out of or in connection with these Terms, or the breach, termination or invalidity thereof, or any other issue which shall arise in virtue of these Terms, shall be referred to and finally settled by arbitration administered by the state of New Mexico under the Rules in force when the Notice of Arbitration is submitted.
                          <br /><br />
                          The law of this arbitration clause shall be United States of America law. The seat of arbitration shall be in the United States. The number of arbitrators shall be one. The arbitration proceedings shall be conducted in the English language.
                          <br /><br />
                          <strong>KodakMoney LLC</strong><br /><br />
                          150 Central Park Square, Suite #2, Los Alamos, NM, 87544, USA
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
