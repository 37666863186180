import React from 'react'
import Footer from './components/Footer'
import MintSection from './components/mint'

function Presale () {
  return (
    <main>
      <MintSection endTime={new Date('01 December 2021 21:00:00 GMT-05:00')} isPublicSale={false} sale='WHITELIST' />
      <Footer />
    </main>
  )
}

export default Presale
