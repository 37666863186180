import React from 'react'
import { Accordion } from 'react-bootstrap'

const questions = [{
  q: 'What is an NFT?',
  a: `NFT stands for non-fungible token, an NFT is a unique piece
  of digital art stored in the blockchain that you can own,
  sell or buy!`
}, {
  q: 'How to get one or more Rich Bulls NFTs?',
  a: 'You will be able to purchase Rich Bulls NFTs from our website when the public sale is live on December 02 at 9 PM EST. Once you buy (mint) your Rich Bull, a randomly selected NFT will be delivered to your <a href="https://metamask.io/">MetaMask</a> wallet. You will then be able to see it and sell it on your <a href="https://opensea.io/">OpenSea</a> account.'
}, {
  q: 'What is the total Rich Bulls supply?',
  a: 'There will be only 9999 unique Rich Bulls available forever. Once it is sold out, you\'ll only be able to buy a Rich Bull NFT on the secondary market, namely <a href="https://opensea.io/collection/richbullsclub/">OpenSea</a>, for a higher price.'
}, {
  q: 'How does it work to buy an NFT?',
  a: 'To buy a Rich Bull NFT when the public sale is live on December 02 at 9 PM EST, you need to have some Ethereum (ETH), which you can buy on <a href="https://coinbase.com/">CoinBase</a> or <a href="https://www.binance.com/">Binance</a>. Then, if you don\'t already have one, you\'ll create a <a href="https://metamask.io/">MetaMask</a> wallet using the MetaMask extension for Google Chrome or the MetaMask Android/IOS app. Afterwards, you have to transfer your Ethereum (ETH) to your MetaMask wallet. Don\'t forget that Ethereum charges you gas fees for every transaction, so you\'ll need enough ETH for mint price + gas fees. Finally, select how much Rich Bulls you want to buy and click on the "Mint" button that will appear on our website when the public sale is live!'
}, {
  q: 'Will there be a presale?',
  a: 'Yes, we will hold a pre-sale 1 day before the public sale for our most active members. Pre-sale price will be twice as low as the public sale price; however, pre-sale buyers will not be allowed to sell their NFTs under a certain price for the sake of the community. 1000 pre-sale slots will be available. Please join our Discord to find out how to get a spot: https://discord.gg/u6zR56asR7'
}, {
  q: 'Are there secondary sale royalties?',
  a: 'Yes, 10% of the secondary sales on OpenSea will be deducted in royalties to fund the projects roadmaps, marketing and pay our team to ensure steady growth and secure profit for every holder.'
}, {
  q: 'Why is Rich Bulls one of the safest NFTs?',
  a: 'Unlike most NFTs, Rich Bulls Club is powered by a registered company incorporated in the United States, which makes us responsible for the project before law and means that we have the legal responsibility to deliver on every commitment we make. Furthermore, on top of being open-source, our smart-contract and minting system will be audited and secured by a professional and trusted team (<a href="https://www.certik.io/">Certik</a>) that includes engineers with experience from Google, Microsoft, Oracle, and Facebook.'
}, {
  q: 'How can I use my Rich Bull NFT?',
  a: 'You will own complete copyrights on your NFT, and you\'ll be able to use it as an avatar to flex it on your social media or even use it to create merch. Furthermore, holding a Rich Bull NFT gives you access to exclusive perks listed in the roadmaps above. You can also resell your Rich Bull for profit later.'
}]

function FAQ () {
  return (
    <section className='faq' id='faq'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 col-md-10 col-lg-9 col-xl-8 fadeInUp'>
            <h2>FAQ?</h2>
            <Accordion>
              {
                questions.map((question, key) => (
                  <Accordion.Item eventKey={key} key={key}>
                    <Accordion.Header>{question.q}</Accordion.Header>
                    <Accordion.Body>
                      <div dangerouslySetInnerHTML={{ __html: question.a }} />
                    </Accordion.Body>
                  </Accordion.Item>
                ))
              }
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FAQ
