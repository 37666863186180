import React from 'react'
import OwlCarousel from 'react-owl-carousel'

function Hero() {
  return (
    <section className='hero-bull' id='hero-bull'>
      <div className='container'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-sm-8 col-md-6 col-xl-6 fadeIn'>
            <span>PRIMAL</span>
            <h2>BULLS</h2>
            <p className='pb-0'>
              According to a <strong>thousand years old legend</strong>, at the
              beginning of time, the great titan Cronus swallowed his 7 children
              to protect his reign of terror. They were the primal bulls:{' '}
              <strong>
                Hestia, Demeter, Hera, Hades, Poseidon, Zeus and Chiron
              </strong>
              . It's now time for them to break free and lead the Rich Bulls army
              to take over the <strong>NFT world</strong>!<br />
              <br /> Primal bulls can be minted like all others, and they are
              generous: if you are lucky enough to mint one, you'll win a
              <strong> bonus of 10 000$</strong> sent directly to your wallet!
            </p>
          </div>
          <div className='col-12 fadeInUp'>
            <OwlCarousel
              className='owl-theme bull-carousel'
              nav
              loop
              margin={15}
              autoplay
              dots={false}
              responsive={{
                0: {
                  items: 1
                },
                600: {
                  items: 3
                },
                1000: {
                  items: 5
                }
              }}
              navText={["<i class='bi bi-arrow-left-short'></i>", "<i class='bi bi-arrow-right-short'></i>"]}
            >
              <div className='item'>
                <div className='bull-slider-bx'>
                  <img className='slide-def-img' src='img/slider-img-hov1.png' alt='Image' />
                  <img className='slide-hov-img' src='img/slider-img-hov1.png' alt='Image' />
                </div>
              </div>
              <div className='item'>
                <div className='bull-slider-bx'>
                  <img className='slide-def-img' src='img/slider-img-hov1.png' alt='Image' />
                  <img className='slide-hov-img' src='img/slider-img-hov1.png' alt='Image' />
                </div>
              </div>
              <div className='item'>
                <div className='bull-slider-bx'>
                  <img className='slide-def-img' src='img/slider-img-hov1.png' alt='Image' />
                  <img className='slide-hov-img' src='img/slider-img-hov1.png' alt='Image' />
                </div>
              </div>
              <div className='item'>
                <div className='bull-slider-bx'>
                  <img className='slide-def-img' src='img/slider-img-hov1.png' alt='Image' />
                  <img className='slide-hov-img' src='img/slider-img-hov1.png' alt='Image' />
                </div>
              </div>
              <div className='item'>
                <div className='bull-slider-bx'>
                  <img className='slide-def-img' src='img/slider-img-hov1.png' alt='Image' />
                  <img className='slide-hov-img' src='img/slider-img-hov1.png' alt='Image' />
                </div>
              </div>
              <div className='item'>
                <div className='bull-slider-bx'>
                  <img className='slide-def-img' src='img/slider-img-hov1.png' alt='Image' />
                  <img className='slide-hov-img' src='img/slider-img-hov1.png' alt='Image' />
                </div>
              </div>
              <div className='item'>
                <div className='bull-slider-bx'>
                  <img className='slide-def-img' src='img/slider-img-hov1.png' alt='Image' />
                  <img className='slide-hov-img' src='img/slider-img-hov1.png' alt='Image' />
                </div>
              </div>
            </OwlCarousel>
            <div className='owl-carousel owl-theme bull-carousel'>
              <div className='item'>
                <div className='bull-slider-bx'>
                  <img
                    className='slide-def-img'
                    src='img/slider-img-hov1.png'
                    alt='Image'
                  />
                  <img
                    className='slide-hov-img'
                    src='img/slider-img-hov1.png'
                    alt='Image'
                  />
                </div>
              </div>
              <div className='item'>
                <div className='bull-slider-bx'>
                  <img
                    className='slide-def-img'
                    src='img/slider-img-hov1.png'
                    alt='Image'
                  />
                  <img
                    className='slide-hov-img'
                    src='img/slider-img-hov1.png'
                    alt='Image'
                  />
                </div>
              </div>
              <div className='item'>
                <div className='bull-slider-bx'>
                  <img
                    className='slide-def-img'
                    src='img/slider-img-hov1.png'
                    alt='Image'
                  />
                  <img
                    className='slide-hov-img'
                    src='img/slider-img-hov1.png'
                    alt='Image'
                  />
                </div>
              </div>
              <div className='item'>
                <div className='bull-slider-bx'>
                  <img
                    className='slide-def-img'
                    src='img/slider-img-hov1.png'
                    alt='Image'
                  />
                  <img
                    className='slide-hov-img'
                    src='img/slider-img-hov1.png'
                    alt='Image'
                  />
                </div>
              </div>
              <div className='item'>
                <div className='bull-slider-bx'>
                  <img
                    className='slide-def-img'
                    src='img/slider-img-hov1.png'
                    alt='Image'
                  />
                  <img
                    className='slide-hov-img'
                    src='img/slider-img-hov1.png'
                    alt='Image'
                  />
                </div>
              </div>
              <div className='item'>
                <div className='bull-slider-bx'>
                  <img
                    className='slide-def-img'
                    src='img/slider-img-hov1.png'
                    alt='Image'
                  />
                  <img
                    className='slide-hov-img'
                    src='img/slider-img-hov1.png'
                    alt='Image'
                  />
                </div>
              </div>
              <div className='item'>
                <div className='bull-slider-bx'>
                  <img
                    className='slide-def-img'
                    src='img/slider-img-hov1.png'
                    alt='Image'
                  />
                  <img
                    className='slide-hov-img'
                    src='img/slider-img-hov1.png'
                    alt='Image'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
