import React from 'react'

function Discover() {
  return (
    <section className='our-bull' id='our-bulls'>
      <div className='container'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-xl-6 fadeInUp'>
            <span>DISCOVER THE</span>
            <h2>RICH BULLS</h2>
            <p>
              Do you feel the ground rumbling under your feet? Do you hear the
              bulls roaring upon the field? A massive, angry army of{' '}
              <strong>9999 rich bulls</strong> will soon run over the NFT world...
              Brace yourselves. Impact estimated{' '}
              <strong>right now</strong>.
            </p>
            <p className='pb-md-0'>
              The <strong>Rich Bulls Club</strong> is a collection of 9999 bullish
              NFTs—unique digital collectibles living on the Ethereum blockchain.
              With over <strong>170+ hand-drawn traits</strong>, your exclusive NFT serves as a membership to an elite club with multiple{' '}
              <strong>members-only benefits</strong>.
            </p>
          </div>
          <div className='col-12 col-md-6 col-xl-5 fadeIn'>
            <img src='img/richbulls.gif' alt='Bull images' />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Discover
