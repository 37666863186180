// log
import store from '../store'
import Web3 from 'web3'

const fetchDataRequest = () => {
  return {
    type: 'CHECK_DATA_REQUEST'
  }
}

const fetchDataSuccess = (payload) => {
  return {
    type: 'CHECK_DATA_SUCCESS',
    payload: payload
  }
}

const fetchDataFailed = (payload) => {
  return {
    type: 'CHECK_DATA_FAILED',
    payload: payload
  }
}

export const fetchData = (account) => {
  return async (dispatch) => {
    // const web3 = new Web3(new Web3.providers.HttpProvider("https://mainnet.infura.io/v3/YOUR_PROJECT_ID"))
    dispatch(fetchDataRequest())
    try {
      const totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call()
      const isWhitelisted = await store
        .getState()
        .blockchain.smartContract.methods.isWhitelisted(account)
        .call()
      const isVip = await store
        .getState()
        .blockchain.smartContract.methods.isVip(account)
        .call()

      const web3 = new Web3(new Web3.providers.HttpProvider(process.env.REACT_APP_MAIN_CHAIN_RPC))
      const balance = await web3.eth.getBalance(account)
      dispatch(
        fetchDataSuccess({
          totalSupply,
          balance: parseInt((balance / 1e18).toFixed(2)),
          isWhitelisted,
          isVip
        })
      )
    } catch (err) {
      dispatch(fetchDataFailed('Could not load data from contract.'))
    }
  }
}
