// constants
import Web3EthContract from 'web3-eth-contract'
import Web3 from 'web3'
import axios from 'axios'
import detectEthereumProvider from '@metamask/detect-provider'

// log
import { fetchData } from '../data/dataActions'

const connectRequest = () => {
  return {
    type: 'CONNECTION_REQUEST'
  }
}

const connectSuccess = (payload) => {
  return {
    type: 'CONNECTION_SUCCESS',
    payload: payload
  }
}

const connectFailed = (payload) => {
  return {
    type: 'CONNECTION_FAILED',
    payload: payload
  }
}

const updateAccountRequest = (payload) => {
  return {
    type: 'UPDATE_ACCOUNT',
    payload: payload
  }
}

export const connect = () => {
  return async (dispatch) => {
    dispatch(connectRequest())
    const abiResponse = await axios.get('/config/abi.json', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    })
    const abi = await abiResponse.data
    const { ethereum } = window
    const metamaskIsInstalled = ethereum && ethereum.isMetaMask
    if (metamaskIsInstalled) {
      Web3EthContract.setProvider(ethereum)
      const web3 = new Web3(ethereum)
      try {
        const accounts = await ethereum.request({
          method: 'eth_requestAccounts'
        })
        const networkId = await ethereum.request({
          method: 'eth_chainId'
        })
        if (parseInt(networkId, 16) === parseInt(process.env.REACT_APP_MAIN_CHAIN_ID) || networkId === process.env.REACT_APP_MAIN_CHAIN_ID) {
          const SmartContractObj = new Web3EthContract(
            abi,
            process.env.REACT_APP_MAIN_CONTRACT_ADDRESS
          )
          dispatch(
            connectSuccess({
              account: accounts[0],
              smartContract: SmartContractObj,
              web3: web3
            })
          )
          // Add listeners start
          ethereum.on('accountsChanged', (accounts) => {
            dispatch(updateAccount(accounts[0]))
          })
          ethereum.on('chainChanged', () => {
            window.location.reload()
          })
          // Add listeners end
        } else {
          await ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: `0x${parseInt(process.env.REACT_APP_MAIN_CHAIN_ID).toString(16)}`,
                chainName: process.env.REACT_APP_MAIN_CHAIN_NAME,
                nativeCurrency: {
                  name: 'ETH',
                  symbol: 'eth',
                  decimals: 18
                },
                rpcUrls: JSON.parse(process.env.REACT_APP_MAIN_CHAIN_NODES),
                blockExplorerUrls: [`${process.env.REACT_APP_MAIN_CHAIN_EXPLORER}/`]
              }
            ]
          })
          dispatch(connect())
        }
      } catch (err) {
        console.log(err)
        dispatch(connectFailed('Something went wrong.'))
      }
    } else {
      dispatch(connectFailed('Install Metamask.'))
    }
  }
}

export const updateAccount = (account) => {
  return async (dispatch) => {
    dispatch(updateAccountRequest({ account: account }))
    dispatch(fetchData(account))
  }
}
