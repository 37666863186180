import React from 'react'

function Roadmap () {
  return (
    <section id='roadmap' className='roadmap'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 col-xl-6'>
            <div className='roadmap-content-bx'>
              <div className='road-cont fadeInUp'>
                <h3>15% - Get bullish together! 🐂</h3>
                <p>
                  A private, holder-only group will be created where{' '}
                  <strong>multiple self-made millionaires</strong>, including our
                  founders, will share their tips and network for free, which will
                  kickstart your career and might as well{' '}
                  <strong>change your life</strong>! <br />
                  <br />
                  Want to promote your restaurant online? We'll put you in touch
                  with the very best{' '}
                  <strong>media buyers &amp; SEO experts</strong>. Need an
                  internship or a job in a tech giant? We know{' '}
                  <strong>top execs</strong> in various NASDAQ companies and can
                  pass your resume to the right people.
                  <br />
                  <br />
                  Are you starting your own crypto or building a mobile app? We'll
                  plug you with the <strong>best devs</strong> out there.
                  Struggling with your online store because of Facebook or Stripe
                  bans? We know people there who can{' '}
                  <strong>make it go away</strong>. Launching your own brand and
                  needing a star to promote it? We can help you book anyone from{' '}
                  <strong>a big YouTuber</strong> to the{' '}
                  <strong>Kardashians</strong>.
                </p>
              </div>
              <div className='road-cont fadeInUp'>
                <h3>25% - Bulls stash  💰</h3>
                <p>
                  Rich Bulls are driven to take over the NFT world, which is why
                  not less than{' '}
                  <strong>100 000 USD + 30% of the royalty fees</strong> will
                  automatically be allocated to a wallet dedicated to the
                  project's growth and marketing with{' '}
                  <strong>100% transparency</strong> and open-source accounting.
                </p>
              </div>
              <div className='road-cont fadeInUp'>
                <h3>50% - Better than Bored Ape 🙈</h3>
                <p>
                  We will take things <strong>to the next level</strong> by using
                  the Bulls stash and our large network to organize regular
                  exclusive events in various locations with{' '}
                  <strong>
                    A-listers, big YouTubers, rappers, world-class DJs, famous sports figures,
                    major influencers, brands reps, top execs and other well-known
                    people
                  </strong>{' '}
                  who seemed out of your reach before.
                  <br />
                  <br /> Invites will be sent randomly to holders based on how
                  long they held their Bull. Your <strong>Rich Bull</strong> will
                  be your <strong>member's card</strong> for one of the most{' '}
                  <strong>elite clubs</strong> out there. Prepare for your status,
                  clout, and career to <strong>skyrocket</strong>!
                </p>
              </div>
              <div className='road-cont fadeInUp'>
                <h3>85% - Mythic bulls awakening! ✨</h3>
                <p>
                  If you are <strong>lucky enough</strong> to mint the Lambo
                  Mythic Bull, the Laren Mythic Bull, or the Rary Mythic Bull, you
                  will win a <strong> Lamborghini Huracan</strong>, a{' '}
                  <strong>2020 McLaren 600LT</strong>, a{' '}
                  <strong>2019 Ferrari 488 GTB</strong> or{' '}
                  <strong>240 000$</strong> in ETH!
                </p>
              </div>
              <div className='road-cont fadeInUp'>
                <h3>100% - REAL LIFE SQUID GAME TO WIN 1M$! 🔥</h3>
                <p>
                  When 100% of the collection is sold, Rich Bulls will start{' '}
                  <strong>an earthquake</strong> in the{' '}
                  <strong>NFT sphere</strong> and organize the{' '}
                  <strong>biggest event in crypto history</strong>, which will be
                  recorded by a TV crew or a major YouTuber and his team.
                  <br />
                  <br /> <strong>100 holders</strong>{' '}
                  (including the 10 owners of Primal and Mythic Bulls) will be
                  randomly chosen to participate in a massive, real-life, and
                  all-inclusive (paid flight, food, and hotel){' '}
                  <strong>4-days Squid Game event</strong> in which the winner
                  will go back home with <strong>1 MILLION US DOLLARS</strong>.{' '}
                </p>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6 col-xl-6 fadeIn'>
            <h2 className='text-end'>ROADMAP</h2>
            <img src='img/roadmap-img.png' loading='lazy' alt='Bull images' />
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 col-md-10 col-lg-9 col-xl-8 text-center fadeInUp'>
            <span>ROADMAP</span>
            <h2>DEVELOPMENT</h2>
            <p>
              Our number one priority is to make sure Rich Bulls Club is a
              long-term hit; therefore, we will implement several innovative
              strategies after all NFTs are sold to make sure your favorite bulls
              make history.
            </p>
          </div>
          <div className='col-12'>
            <div className='devlopment-road-time'>
              <div className='road-dev-txt text-md-end rght-circl pb-5 pe-5 fadeInRight'>
                <h3>BULLS TAKE OVER THE WORLD</h3>
                <p>
                  After selling out, we will launch an{' '}
                  <strong>insane marketing campaign</strong> around Rich Bulls and
                  our <strong>massive Squid Game event</strong>! Expect many
                  partnerships &amp; collaborations with stars, influencers, top
                  YouTubers, and prominent actors of the crypto sphere. We'll make{' '}
                  <strong>more noise than Crypto Punks</strong>.
                </p>
              </div>
              <div className='road-dev-emp' />
              <div className='road-dev-emp' />
              <div className='road-dev-txt pb-5 left-circl ps-5 fadeInLeft'>
                <h3>SCARCITY</h3>
                <p>
                  There will never be <strong>future drops</strong>; there will be
                  no <strong>breeding</strong>, there will be no{' '}
                  <strong>new Rich Bulls collections</strong>. The Rich Bulls Club
                  will only count 9999 NFTs,{' '}
                  <strong>forever</strong>.<br />
                  <br /> After we sell out, we will
                  <strong>
                    {' '}massively{' '}
                  </strong>{' '}
                  invest in marketing, events, and partnerships to increase the
                  demand while maintaining a fixed scarce supply, which means the{' '}
                  <strong>price will go up and up and up</strong>, so get one
                  while you can and brace yourself!
                </p>
              </div>
              <div className='road-dev-txt text-md-end rght-circl pb-5 pe-5 fadeInRight'>
                <h3>INCUBATOR</h3>
                <p>
                  After the <strong>large-scale marketing campaigns</strong> are
                  rolled-out and Rich Bulls Club has reached{' '}
                  <strong>worldwide awareness</strong>, we will use the Bulls
                  stash to regularly select holders,{' '}
                  <strong>invest in their businesses</strong>, and leverage our
                  network to make them <strong>blow up</strong>.<br />
                  <br />
                  We will form a <strong>new LLC</strong> to handle this, and{' '}
                  <strong>80% of the profit</strong> generated by these
                  investments will be <strong>distributed weekly</strong> among
                  our{' '}
                  <strong>long-term holders (6+ months)</strong>. You might end up
                  being a shareholder of the <strong>next unicorn!</strong>
                </p>
              </div>
              <div className='road-dev-emp' />
              <div className='road-dev-emp' />
              <div className='road-dev-txt left-circl ps-5 fadeInLeft'>
                <h3>NFT INVESTMENT FUND</h3>
                <p>
                  Anyone can have a great idea, but not everyone has the funds to{' '}
                  <strong>launch an NFT collection</strong>. Thus, we will create
                  the first-ever <strong>NFT investment fund</strong> backed by
                  its own <strong>cryptocurrency</strong>, only available to Rich
                  Bulls holders.
                  <br />
                  <br />
                  <strong>NFT projects founders</strong> looking for funding will
                  apply through a dedicated form, and Rich Bulls owners will vote
                  for the <strong>best projects</strong>. Holders will then be
                  able to invest in these projects <strong>pre-launch</strong> in
                  exchange for a percentage of <strong>minting revenues</strong>{' '}
                  which is <strong>far more profitable</strong> than a pre-sale
                  slot!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Roadmap
